



























































































































































































































































































































































































































































































import { parseDate, USER_ROLES } from "@/utils";
import { Component, Vue } from "vue-property-decorator";
import SlVueTree from "sl-vue-tree";
import { Store } from "vuex";
import {
  readProject,
  readBuildings,
  readUploadedFiles,
  readGroups,
  readEvents,
  readDevices,
} from "@/store/main/getters";
import {
  dispatchGetProject,
  dispatchUnassignProjectForUsers,
  dispatchGetEvents,
  dispatchGetBuildings,
  dispatchDeleteBuilding,
  dispatchGetFilesByProject,
  dispatchGetGroups,
  dispatchGetDevices,
} from "@/store/main/actions";
import { dispatchGetAssignedUsersByProject } from "@/store/admin/actions";
import { readAdminUsers } from "@/store/admin/getters";

@Component({
  components: {
    SlVueTree,
  },
})
export default class Detail extends Vue {
  public loading = true;
  public groupLoading = true;
  public roles = USER_ROLES;
  public parseDate = parseDate;
  public id = "";
  public name = "";
  public dialog = false;
  public user_id = "";
  public nodes: any[] = [];
  public groupNodes: any[] = [];
  public childGroups = [];
  public childDevices: any[] = [];
  public userHeaders = [
    {
      text: "Username",
      sortable: true,
      value: "username",
      align: "left",
    },
    {
      text: "Email",
      sortable: true,
      value: "email",
      align: "left",
    },
    {
      text: "Full Name",
      sortable: true,
      value: "full_name",
      align: "left",
    },
    {
      text: "Is Active",
      sortable: true,
      value: "is_active",
      align: "left",
    },
    // {
    //   text: 'Is Admin',
    //   sortable: true,
    //   value: 'isSuperuser',
    //   align: 'left',
    // },
    {
      text: "Role",
      sortable: true,
      value: "role",
      align: "left",
    },
    {
      text: "Actions",
      sortable: false,
      value: "id",
      align: "center",
    },
  ];
  public qrHeaders = [
    {
      text: "Username",
      sortable: true,
      value: "username",
      align: "left",
    },
    // {
    //   text: 'Role',
    //   sortable: true,
    //   value: 'role',
    //   align: 'left',
    // },
    {
      text: "Is Active",
      sortable: true,
      value: "is_active",
      align: "left",
    },
    {
      text: "Expired at",
      sortable: true,
      value: "expired_date",
      align: "left",
    },
    {
      text: "QR image",
      sortable: false,
      value: "qr_image",
      align: "left",
    },
    {
      text: "Actions",
      sortable: false,
      value: "id",
      align: "center",
    },
  ];
  public eventHeaders = [
    {
      text: "Name",
      sortable: true,
      value: "name",
      align: "left",
    },
    {
      text: "Type",
      sortable: true,
      value: "type",
      align: "left",
    },
  ];
  public buildingHeaders = [
    {
      text: "Name",
      sortable: true,
      value: "name",
      align: "left",
    },
    {
      text: "Address",
      sortable: true,
      value: "address",
      align: "left",
    },
    {
      text: "Number of floorplans",
      sortable: true,
      value: "amount_of_storey",
      align: "left",
    },
    {
      text: "Actions",
      sortable: false,
      value: "id",
      align: "center",
    },
  ];

  public panel = [true, false, false, false, false];
  // public groupsHeader = [
  //   {
  //     text: 'Group Name',
  //     sortable: true,
  //     value: 'name',
  //     align: 'left',
  //   },
  //   {
  //     text: 'Type',
  //     sortable: true,
  //     value: 'type',
  //     align: 'left',
  //   },
  //   {
  //     text: 'Is featured',
  //     sortable: true,
  //     value: 'is_active',
  //     align: 'left',
  //   },
  // ];

  get events() {
    return readEvents(this.$store);
  }

  get project() {
    return readProject(this.$store);
  }

  get users() {
    return readAdminUsers(this.$store).filter((u) => u.type !== "qr");
  }

  get qrs() {
    return readAdminUsers(this.$store).filter((u) => u.type === "qr");
  }

  get buildings() {
    return readBuildings(this.$store);
  }

  get files() {
    return readUploadedFiles(this.$store);
  }

  get groups() {
    return readGroups(this.$store);
  }

  public navigatorLink(type) {
    return `main-${type}-edit`;
  }

  public getFloorplansByBuilding(id) {
    return this.files.filter((f) => f.building_id === id);
  }

  public getGroupsByFile(id) {
    return this.groups.filter((f) => f.file_id === id);
  }

  public getDevicesByFile(id) {
    return this.devices.filter((f) => (f as any).file_id === id);
  }

  get getProjectId() {
    return Number(this.$router.currentRoute.params.id);
  }

  get devices() {
    return readDevices(this.$store);
  }

  public getGroup(id: any) {
    return this.groups.find((x) => x.id === id);
  }

  public getDevice(id: any) {
    return this.devices.find((x) => x["id"] === id);
  }

  get fileId() {
    return Number(this.$router.currentRoute.params.id);
  }

  public parseGroup(id: any, device_id = null) {
    if (device_id) {
      const device = this.getDevice(device_id);
      if (!device) {
        return null;
      }
      return {
        isLeaf: true,
        isDraggable: false,
        isExpanded: false,
        title: device["block_name"],
        data: {
          id: device_id,
          type: device["type"],
          serial: device["serial_number"],
          channels: device["channels"],
          navigator: "devices",
        },
      };
    }
    const group = this.getGroup(id);
    if (!group) {
      return null;
    }
    group["data"] = {
      id: id,
      type: "group",
      navigator: "groups",
    };
    // group['groups'] = [];
    group["isLeaf"] = true;
    group["isExpanded"] = false;
    group["isDraggable"] = false;
    group["title"] = group.name;
    if (group.group_ids.length > 0 || group.device_ids.length > 0) {
      group["children"] = [];
      for (let i = 0; i < group.group_ids.length; i++) {
        let child = this.parseGroup(group.group_ids[i]);
        if (child) {
          group["isLeaf"] = false;
          group["isExpanded"] = true;
          group["children"].push(child);
        }
        // group['groups'][i] = this.parseGroup(group.group_ids[i]);
      }
      // for(let i = 0; i < group.device_ids.length; i++) {
      //   let data = this.parseGroup(id, group.device_ids[i]);
      //   if (data){
      //     group['isLeaf'] = false;
      //     group['isExpanded'] = true;
      //     group['children'].push(data);
      //   }
      // }
    } else {
      // isleaf
      group["isLeaf"] = true;
    }
    return group;
  }

  public groupDataByFile(id) {
    this.groupNodes = [];
    this.childGroups = [];
    this.childDevices = [];
    let filteredGroup = this.getGroupsByFile(id);
    // let filteredDevices = this.getDevicesByFile(id);

    if (filteredGroup.length > 0) {
      filteredGroup.forEach((group) => {
        this.childGroups.push(...group.group_ids);
        // this.childDevices.push(...group.device_ids);
      });

      filteredGroup.forEach((group) => {
        if (!this.childGroups.includes(group.id as never)) {
          this.groupNodes.push(this.parseGroup(group.id));
        }
      });

      // let devices = [];
      // if (filteredDevices.length>0){
      //   filteredDevices.forEach(device => {
      //     if (!this.childDevices.includes(device['id'] as never)){
      //       devices.push({
      //         'title': device['block_name'],
      //         'isDraggable': false as never,
      //         'isExpanded': false  as never,
      //         'isLeaf': true  as never,
      //         'data': {
      //           id: device['id'],
      //           type: device['type'],
      //           serial: device['serial_number'],
      //           channels: device['channels'],
      //           navigator: 'devices'
      //         }
      //       } as never);
      //     }
      //   });

      //   this.groupNodes.push({
      //     'title': 'Unassigned',
      //     'isDraggable': false,
      //     'isExpanded': false,
      //     'children': devices,
      //     'isLeaf': false
      //   });
      // }
    }
  }

  public async mounted() {
    await dispatchGetProject(this.$store, this.getProjectId);
    await dispatchGetAssignedUsersByProject(this.$store, this.getProjectId);
    await dispatchGetEvents(this.$store, this.getProjectId);
    await dispatchGetBuildings(this.$store, this.getProjectId);
    await dispatchGetFilesByProject(this.$store, this.getProjectId);
    await dispatchGetGroups(this.$store, this.getProjectId);
    // await dispatchGetDevices(this.$store, this.getProjectId);
    this.loading = false;

    if (this.buildings) {
      this.buildings.forEach((building) => {
        let floorplans = this.getFloorplansByBuilding(building.id);
        building["type"] = "building";
        building["navigator"] = "buildings";
        let data = {
          title: building.name,
          isDraggable: false,
          isExpanded: floorplans.length > 0,
          children: [] as any,
          isLeaf: floorplans.length == 0,
          data: building,
        };
        floorplans.forEach((floorplan) => {
          floorplan["type"] = "floorplan";
          floorplan["navigator"] = "files";
          this.groupDataByFile(floorplan.id);
          data["children"].push({
            title: floorplan.full_name,
            isDraggable: false,
            isExpanded: false,
            children: this.groupNodes,
            isLeaf: this.groupNodes.length == 0,
            data: floorplan,
          });
        });
        data.children = data.children.sort(
          (a, b) => b.data.position - a.data.position
        );
        this.nodes.push(data);
      });
    }
    this.groupLoading = false;
  }

  public async confirm() {
    this.dialog = false;
    await dispatchDeleteBuilding(this.$store, Number(this.id));
    await dispatchGetBuildings(this.$store, this.getProjectId);
  }

  public async unassignUser(userId) {
    await dispatchUnassignProjectForUsers(this.$store, {
      projectId: this.getProjectId,
      data: [userId as never],
    });
    await dispatchGetAssignedUsersByProject(this.$store, this.getProjectId);
  }

  public goBack() {
    this.$router.push("/main/projects/all");
  }
}
